export const ADVANTAGES_LIST = [
	{
		title: "Адаптивный дизайн",
		text: "Это позволит пользоваться сайтом одинаково удобно на всех устройствах",
		titleIcon: "dimensioms.svg",
	},
	{
		title: "Удобная система управления сайтом",
		text: "Хорошая админка облегчает внесение изменений на сайт, редактирование контента или добавление новых элементов",
		titleIcon: "pencil.svg",
	},
	{
		title: "SEO-оптимизация",
		text: "В течение времени это продвинет ваш сайт выше в выдаче при поисковых запросах и увеличит органический трафик на сайте",
		titleIcon: "alignBottom.svg",
	},
	{
		title: "Поддержка и расширение",
		text: "После запуска проекта его нужно поддерживать, обновлять и при необходимости расширять, улучшать. Поэтому после оформления поддержки - первый месяц за наш счет",
		titleIcon: "commit.svg",
	},
	{
		title: "SPA-приложения",
		text: "Наши сайты - это настоящие Single Page Applications. Мы используем технологии, которые делают сайт максимально быстрым, функциональным и удобным в контексте пользовательского опыта. [Подробнее](https://piybeep.com/studio)",
		titleIcon: "reader.svg",
	},
	{
		title: "Аналитика",
		text: "На каждый сайт мы подключаем Яндекс.Метрику (и/или Google Analytics). Эти сервисы будут отслеживать действия пользователей на сайте, анализировать данные о посетителях, собирать статистику рекламных кампаний. Очень полезный инструмент для любого владельца сайта",
		titleIcon: "lupa.svg",
	},
];
