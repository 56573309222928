export const TEXT_SLIDER = [
	"заинтересуют потенциальных покупателей",
	"продадут ваш продукт",
	"привлекут целевую аудиторию",
	"улучшат позиционирование компании",
	"будут уникальными среди конкурентов",
];

export const TEXT_SLIDER_BIZ = [
	"сократят издержки компании",
	"решают бизнес-задачи",
	"создают логические решения",
	"автоматизируют бизнес-процессы",
	"будут уникальными решениями для бизнеса",
];
